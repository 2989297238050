import React, { ReactNode, useEffect } from 'react';
import { PageProps } from 'gatsby';
import Header from './Header';
import Footer from './Footer';
import LomeLogo from './lome-logo/LomeLogo';
import PageTransition from './ui/PageTransition';
import GridGuid from './ui/GridGuid';
import DesignSystem from './ui/DesignSystem';
import * as smoothscroll from 'smoothscroll-polyfill';
import { subscribe, unsubscribe } from 'pubsub-js';
import usePageContext from 'context/PageContext';
// import { subscribe, unsubscribe } from 'pubsub-js';

//{ children, pageContext, location }
let windowWith = 0;
const Layout: React.FC<PageProps> = ({ children, pageContext, location }) => {
  // const { location } = usePageContext();
  useEffect(() => {
    smoothscroll.polyfill();
    // console.log('heeeeeeee');
    _handleDevice();
    _onResize();
    window.addEventListener('resize', _onResize);

    const tokenRoute = subscribe('ROUTE_UPDATE', (e, d) => {
      // window.scroll(0, 0);
      _onResize();
    });
    const tokenPreRoute = subscribe('PRE_ROUTE_UPDATE', (e, d) => {
      // window.scroll(0, 0);
      // document.scrollingElement?.scroll(0, 0);
    });

    const tokenAnimation = subscribe('LOME_LOGO_ANIMATION_END', () => {
      document.body.classList.add('is-ready');
    });

    return () => {
      window.removeEventListener('resize', _onResize);
      unsubscribe(tokenRoute);
      unsubscribe(tokenPreRoute);
      unsubscribe(tokenAnimation);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isHome = location.pathname === '/';
    document.documentElement.classList.toggle('with-scroll-snap', isHome);
  }, [location]);

  const _onResize = () => {
    // console.log('resize');
    // console.log(
    //   document.documentElement.style.getPropertyValue('--app-height'),
    // );
    if (windowWith !== window.innerWidth) {
      document.documentElement.style.setProperty(
        '--app-height',
        window.innerHeight + 'px',
      );
    }
    // document.documentElement.style.setProperty(
    //   '--app-height',
    //   window.innerHeight + 'px',
    // );

    const header = document.querySelector('header');
    if (header) {
      const headerBounding = header.getBoundingClientRect();

      document.documentElement.style.setProperty(
        '--header-height',
        headerBounding.height + 'px',
      );
    }

    windowWith = window.innerWidth;
  };

  const _handleDevice = () => {
    const isSafari =
      navigator.vendor &&
      navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') == -1 &&
      navigator.userAgent.indexOf('FxiOS') == -1;

    // console.log({ isSafari });
    if (isSafari) {
      document.documentElement.classList.add('is-safari');
    }
  };

  return (
    <div id="page">
      <Header />
      <main className="z-10">
        <PageTransition location={location}>
          <div className="layout-children">{children}</div>
        </PageTransition>
      </main>

      <div className="ghost-spacer h-screen pointer-events-none snap-start"></div>
      <div className="spacer-gradient fixed bottom-0 left-0 w-full z-20"></div>
      <LomeLogo />
      <Footer />
      {/* <GridGuid /> */}
    </div>
  );
};

export default Layout;
