import { useStaticQuery, graphql } from 'gatsby';
import { SanitySettings } from '../../graphql-types';

export const useSiteMetadata = () => {
  const { site, settings, sanityProjectsIndex } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          buildTime(formatString: "YYYY-MM-DD")
          siteMetadata {
            defaultTitle
            defaultDescription
            defaultImage
            siteUrl
            ogLanguage
            author
          }
        }
        settings: sanitySettings {
          nav {
            label {
              ...localeString
            }
            link {
              ... on SanityInfos {
                _type
                slug {
                  current
                }
              }
              ... on SanityProjectsIndex {
                _type
                slug {
                  current
                }
              }
            }
          }
        }
        sanityProjectsIndex {
          _id
          id
          seo {
            ...seo
          }

          projects {
            ...projectCard
          }
          tags {
            _id
            title
          }
        }
      }
    `,
  );
  // console.log(global)
  return {
    buildTime: site.buildTime,
    site: site.siteMetadata,
    settings: settings as SanitySettings,
    sanityProjectsIndex: sanityProjectsIndex,
  };
};
