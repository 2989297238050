import React from 'react';

type Props = {};

const Footer = (props: Props) => {
  return (
    <footer className="hidden md:block">
      Lome © {new Date().getFullYear()}
    </footer>
  );
};

export default Footer;
