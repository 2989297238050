import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useMemo } from 'react';

const duration = 0.4;

const variants = {
  initial: {
    opacity: 0,
    // y: '100%',
    // y: '0',
  },
  enter: {
    opacity: 1,
    // y: ' 0',
    transition: {
      duration: duration,
      delay: duration * 2,
      when: 'beforeChildren',
      ease: [0.775, 0, 0.17, 1.005],
    },
  },
  exit: {
    opacity: 0,
    // y: '0',
    transition: {
      duration: duration,
      // delay: duration,
      // when: 'beforeChildren',
    },
  },
};

const PageTransition = ({ location, children }) => {
  // console.log(location);
  // const isHome = useMemo(() => {
  //   return location.pathname === '/';
  // }, []);

  // if (isHome) {
  //   variants.initial.y = '50vh';
  //   variants.enter.transition.delay = 10.2;
  //   variants.enter.transition.duration = 1;
  // }

  // const _onAnimationComplete = (definition) => {
  //   console.log(definition);
  //   if (isHome) {
  //     document.documentElement.classList.add('is-animation-complete');
  //   }
  // };

  return (
    <AnimatePresence>
      <motion.div
        className="page-transition"
        key={location.pathname}
        variants={variants}
        initial="initial"
        animate="enter"
        exit="exit"
        // ref={scrollRef}
        // onAnimationComplete={_onAnimationComplete}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

export default PageTransition;
