import React, { useEffect, useState } from 'react';
import { publish } from 'pubsub-js';
import { Link } from 'gatsby';
import { useSiteMetadata } from 'hooks/useSiteMetadata';
import { _linkResolver, _localizeField } from 'utils/utils';
import { SanitySettings, SanityLinkInternal } from '../../graphql-types';

type Props = {};

const Header = (props: Props) => {
  // const { settings } = useSiteMetadata();
  const settings: SanitySettings = useSiteMetadata().settings;
  // console.log(settings);

  // const [isInfos, setIsInfos] = useState<Boolean>(false);

  // const _toggleInfos = () => {
  //   setIsInfos(!isInfos);
  // };

  // useEffect(() => {
  //   publish('INFOS', isInfos);
  // }, [isInfos]);

  return (
    <header className="fixed top-0 w-full p-sm md:p-md text-lg z-20">
      <div className="flex between justify-between">
        <div className="col-xs-">
          <div className="site-name">
            <Link to={'/'}>LOME STUDIO</Link>
          </div>
        </div>
        <div className="col-md-2- w-1/2 md:w-1/4">
          <nav className="flex items-end- justify-end">
            <ul className="flex justify-between md:w-1/2 ">
              {settings.nav?.map((item, i) => (
                <li key={i}>
                  <Link
                    to={item.link ? _linkResolver(item.link) : '/'}
                    className="pr-1e md:pr-0"
                  >
                    {item.label && _localizeField(item.label)}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
