import React, { useEffect, useRef } from 'react';
import { subscribe, unsubscribe } from 'pubsub-js';
import { publish } from 'pubsub-js';

import LTop from './letters/l-top.inline.svg';
import LStretcher from './letters/l-stretcher.inline.svg';
import LBottom from './letters/l-bottom.inline.svg';

import OTop from './letters/o-top.inline.svg';
import OStretcher from './letters/o-stretcher.inline.svg';
import OBottom from './letters/o-bottom.inline.svg';

import MTop from './letters/m-top.inline.svg';
import MStretcher from './letters/m-stretcher.inline.svg';
import MBottom from './letters/m-bottom.inline.svg';

import ETop from './letters/e-top.inline.svg';
import EStretcher from './letters/e-stretcher.inline.svg';
import EBottom from './letters/e-bottom.inline.svg';
import clsx from 'clsx';
// import E from './letters/e.inline.svg';
import './lome-logo.scss';

type Props = {};

const Letter = ({ children, initial }) => {
  // const _onMouseEnter = ({ target }) => {
  //   // console.log(target);
  //   if (target.classList.contains('is-active')) return;

  //   target.classList.add('is-active');
  //   setTimeout(() => {
  //     target.classList.remove('is-active');
  //   }, 1000);
  // };

  return (
    <div
      className={clsx(
        `letter letter--${initial}`,
        'shrink-0- flex flex-col w-1/6 justify-end h-full',
      )}
      // onMouseEnter={_onMouseEnter}
    >
      {children}
    </div>
  );
};

const LomeLogo = (props: Props) => {
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    _onResize();
    _animeWave();
    window.addEventListener('resize', _onResize);

    const tokenRoute = subscribe('PRE_ROUTE_UPDATE', (e, d) => {
      _animeWave();
    });

    return () => {
      window.removeEventListener('resize', _onResize);
      unsubscribe(tokenRoute);
    };
  }, []);

  const _onResize = () => {
    if (!ref.current) return;
    // return;
    const logoBounding: DOMRect = ref.current.getBoundingClientRect();
    // console.log({ logoBounding });
    document.documentElement.style.setProperty(
      '--logo-height',
      logoBounding.height + 'px',
    );

    const stretchers = ref.current.querySelectorAll<HTMLElement>('.stretcher');
    stretchers.forEach((element: HTMLElement, i) => {
      const svg = element.querySelector('svg');
      if (svg) {
        const viewBoxRaw: string = svg?.getAttribute('viewBox');
        const viewBox: Array<string> = viewBoxRaw.split(/\s+|,/);
        const aspectRatio: number =
          parseFloat(viewBox[2]) / parseFloat(viewBox[3]);

        const { width } = element.getBoundingClientRect();
        const height: number = Math.round(width / aspectRatio);
        // console.log(height);
        element.dataset.baseHeight = `${height}`;
        // element.style.flexBasis = `${height}px`;
        element.style.setProperty('--flex-basis', height + 'px');
        element.style.setProperty('--flex-basis-max', height * 3 + 'px');
      }
    });
  };

  const _animeWave = () => {
    if (!ref.current) return;
    // console.log('wave');
    let delayEnd = 0;
    const stretchers = ref.current.querySelectorAll<HTMLElement>('.stretcher');
    const length = stretchers.length;
    stretchers.forEach((element: HTMLElement, i: number) => {
      const index = i * 1000;
      const delayIn = index / 7;
      const delayOut = delayIn + 800;
      const parentNode = element.parentNode as HTMLElement;
      delayEnd = delayOut + 300;
      setTimeout(() => {
        if (parentNode) parentNode.classList.add('is-active');
      }, delayIn);

      setTimeout(() => {
        if (parentNode) parentNode.classList.remove('is-active');
      }, delayOut);
    });

    setTimeout(() => {
      // console.log(delayEnd);
      publish('LOME_LOGO_ANIMATION_END');
    }, delayEnd);
  };

  return (
    <div className="lome-logo fixed bottom-0 w-full p-sm md:p-md z-0" ref={ref}>
      <div className="flex flex-nowrap items-end gap-md- justify-between ">
        <Letter initial="l">
          <div className="part top">
            <LTop />
          </div>
          <div className="part stretcher">
            <LStretcher />
          </div>
          <div className="part bottom">
            <LBottom />
          </div>
        </Letter>

        <Letter initial="o">
          <div className="part top">
            <OTop />
          </div>
          <div className="part stretcher">
            <OStretcher />
          </div>
          <div className="part bottom">
            <OBottom />
          </div>
        </Letter>

        <Letter initial="m">
          <div className="part top">
            <MTop />
          </div>
          <div className="part stretcher">
            <MStretcher />
          </div>
          <div className="part bottom">
            <MBottom />
          </div>
        </Letter>

        <Letter initial="e">
          <div className="part top">
            <ETop />
          </div>
          <div className="part stretcher">
            <EStretcher />
          </div>
          <div className="part bottom">
            <EBottom />
          </div>
        </Letter>
      </div>
    </div>
  );
};

export default LomeLogo;
